import React from 'react'
import ProjectComponent from "../components/projectComponent";
import { ProjectData } from '../components/projectData';
export default function projectSection() {
  return (
    <div className='projectPage '>
        <h1 className='font-weight-bold'>Projects</h1>
        {ProjectData.map((val, key) => {
                return (
                    <ProjectComponent
                    siteName={val.name}
                    techIcons={val.icons}
                    txtOutput={val.textContent}
                    />
                );
            })}

    </div>
  )
}
