import React from "react";
import { SiHtml5, SiJavascript, SiSass, SiGatsby, SiCss3, SiReact, SiPhp } from "react-icons/si";

export const ProjectData = [
    {
        name: "Personal Website",
        icons: <div><SiHtml5 /> <SiJavascript /> <SiGatsby /> <SiSass /></div>,
        textContent: "Personal portfolio designed to provide information to future employers on who I am, what projects I have worked on, and access to my resume if needed. This portfolio is helping me develop my skills in Gatsby.js and SASS. Site is always under construction",
    },
    {
        name: "Spacegram",
        icons: <div><SiReact /> <SiHtml5 /> <SiSass /> <SiJavascript /></div>,
        textContent: "Developed a React web application to allow users to search and select their favourite images from NASA's image library. Designed a pop-up window to create a clean and simplified user interface. Utilized local storage, which allows users to save images they select.",
    },
    {
        name: "Hospital Management System",
        icons: <div><SiHtml5 /> <SiCss3 /> <SiJavascript /> <SiPhp /></div>,
        textContent: "Tool designed to automate daily activities of a hospital that will improve patient care and quality of work. Used HTML/CSS to design webpages and a combination of PHP and MySQL to promote an efficient data storage and retrieval process. ",
    },
    {
        name: "Cake Factory",
        icons: <div><SiReact /> <SiHtml5 /> <SiCss3 /> <SiJavascript /></div>,
        textContent: "Designed application to replace manual forms for customer to place orders online. Various validations were used to make sure user enters all the required information",
    },
    {
        name: "Flappy Birds",
        icons: <div><SiHtml5 /> <SiCss3 /> <SiJavascript /> <SiPhp /></div>,
        textContent: "Web Programming cumulative group project to design a game and webpage to promote game. HTML/CSS used to design webpage and game, JavaScript used to make the game function, and PHP used to send and retrieve data from local database for the leaderboard and review page",
    },
]