import React from 'react'
import "../styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderBar from '../components/headerBar';
import ProjectSection from "../components/projectSection"
export default function projects() {
    return (
        <div>
            <HeaderBar />
            <ProjectSection />
        </div>
    )
}
