import React from 'react'

export default function projectComponent({siteName,techIcons,txtOutput}) {
  return (
    <div className='shadow-lg mt-2 p-3 bg-white rounded projectContainer'>
        <div className='projectSection1'>
            <h2>{siteName} <span>{techIcons}</span> </h2>  
        </div>
        <hr className='w-100'/>
        <div className='projectSection2'>
            <p className='text-wrap'>{txtOutput}</p>
        </div>
    </div>
  )
}
